import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import { AuthContextProvider } from "./contexts/AuthContext";
import { JobContextProvider } from "./contexts/JobContext";
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { ClerkProvider, useAuth } from '@clerk/clerk-react';

import "./i18next";

const { ToastProvider } = require("react-toast-notifications");
const publishableKey = "pk_test_Z2VuZXJvdXMtcGFudGhlci0xNi5jbGVyay5hY2NvdW50cy5kZXYk"


ReactDOM.render(
  <ToastProvider
    autoDismiss={true}
    autoDismissTimeout={6000}
    PlacementType="top-center"
  >
    <AuthContextProvider>
      <JobContextProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <ClerkProvider
           publishableKey={publishableKey}
         >
            
          <App />
          </ClerkProvider>
        </Suspense>
      </JobContextProvider>
    </AuthContextProvider>
  </ToastProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
