import React,{ useEffect,useState,useContext } from 'react';
// import { GoogleLogin } from '@react-oauth/google';
import swal from "sweetalert";
import AxiosConfig from '../AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SignedIn, SignedOut, SignInButton, UserButton,useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";

import { Helmet } from "react-helmet";
import jwtDecode, { JwtPayload } from "jwt-decode";



const Login = () => {

    const { isSignedIn,user,isLoaded } = useUser();
    console.log("🚀 ~ Login ~ user:", user,isLoaded)
  const { addToast } = useToasts();
  const [submitted, setSubmitted] = useState(false);
  const [phoneError,setPhoneError] = useState("")
  const [isNavigate, setIsNavigate] = useState(false);
  const authContext = useContext(AuthContext);
  console.log("🚀 ~ Login ~ authContext:", authContext,isSignedIn)

  


  
  useEffect(() => {
      console.log("testing",authContext?.state?.isAuthenticated,isSignedIn == true)
    if (isSignedIn == true && authContext?.state?.isAuthenticated == false) {
        handleSubmit()
    //  <Navigate to="/job"/> 
    }
  }, [isSignedIn]);


const handleSubmit = (evt) => {
    evt?.preventDefault();

    const phone = user?.phoneNumbers?.[0]?.phoneNumber?.toString().split("+91").join("")
    const email = user?.primaryEmailAddress?.emailAddress
    const firstName = user?.firstName
    const lastName = user?.lastName;
    const role = "recruiter"

    const postData = {
        email: email,
        phone: +phone,
        password: "googlelogin",
        password2: "googlelogin",
        role: role,
        name: {
          first: firstName,
          last: lastName,
        },
      };
  
      setSubmitted(true);
      AxiosConfig.post("auth/signup", postData)
        .then((res) => {
            let decoded = jwtDecode(res.data.token);
        console.log("decoded", decoded);
        console.log("id: decoded.sub", decoded.sub);
        authContext.authDispatch({
          type: authContext.ActionTypes.LOGIN,
          payload: {
            user: decoded.user || {},
            token: res.data.token,
            refreshToken: res.data.refresh,
            id: decoded.sub || null,
          },
        });
        addToast("Logged in successfully", {
          appearance: "success",
          autoDismiss: true,
        });
        //   console.log("resgister res", res);
        //   swal("Good job!", "Successfully registered!", "success");
          setSubmitted(false);
        //   addToast("Registered successfully", { appearance: "success" });
          if (res.status == 201) <Navigate to="/jobs"/>;
        })
        .catch((err) => {
          setSubmitted(false);
  
          if (err.response.status == 409) {
            setPhoneError(
              err.response.data.message ?? "This number is already register!"
            );
            return;
          }
          if (err.response.status == 500) {
            swal("Good job!", "Successfully registered!", "error");
            setSubmitted(false);
            addToast("Something went wrong. Try after sometime", {
              appearance: "error",
            });
            setIsNavigate(true);
            console.log("error 500", err);
            return;
          }
          console.log("register err", err);
          setSubmitted(false);
          addToast("Register failed!", { appearance: "error" });
        });
  };

  return (
    <>
    {/* <h1>Hello Clerk!</h1> */}
      <SignedIn >
        <UserButton afterSignOutUrl={window.location.href} />
        
      </SignedIn>
      <SignedOut>
        <SignInButton mode='modal' />
      </SignedOut></>
  );
};

export default Login;
