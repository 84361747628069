/* eslint-disable */
import React, { useContext, useEffect, useState, FC } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import moment from "moment";
import BaseRouter from "./routes";
import { AuthContext } from "./contexts/AuthContext";
import { ClerkProvider, useAuth } from '@clerk/clerk-react';


const App: FC = () => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, user } = authContext.state;
  const [token, setToken] = useState("");
  const { isSignedIn, signOut,  } = useAuth();
  console.log("🚀 ~ signOut:", signOut)

  useEffect(() => {
    // let decoded = {};
    // let exceptRoutes = ["/jobs", "/applied-jobs"];
    // if (token) {
    //     decoded = jwtDecode(token);
    //     console.log(moment.unix(decoded.exp).format());
    //     console.log(moment().format());
    //     if (moment.unix(decoded.exp).format() < moment().format()) {
    //         localStorage.clear();
    //         history.push('/');
    //     }
    // } else {
    //     if (!exceptRoutes.includes(history.location.pathname)) {
    //         history.push('/');
    //     }
    // }
    // if (decoded.user && jwtToken) {
    //     setToken(jwtToken);
    //     let user = decoded.user;
    //     authContext.authDispatch({
    //         type: authContext.ActionTypes.LOGIN,
    //         payload: {
    //             user,
    //             token,
    //         },
    //     });
    // }
  }, []);

  function handleSignOut() {
  // Clears all data stored in local storage
  localStorage.clear();
  console.log("Local storage cleared and user signed out.");
  
  // Redirect the user after sign out, if needed
  window.location.href = '/';
}

useEffect(() => {
  console.log("🚀 ~ useEffect ~ isSignedIn:", isSignedIn)
  if (localStorage.getItem("user") && isSignedIn == false) {
    localStorage.clear();
    authContext.authDispatch({
      type: authContext.ActionTypes.LOGOUT,
      payload: {},
    });
    console.log("Local storage cleared due to sign out.");
    
  }
}, [isSignedIn]);

  return (
    <React.Fragment>
      <Router>
        <div
          id="page-container"
          className="enable-page-overlay side-scroll page-header-fixed page-header-dark main-content-narrow side-trans-enabled"
        >
          <BaseRouter />
        </div>
      </Router>
      {/* <Spinner isLoading={isLoading}/> */}
    </React.Fragment>
  );
};

export default App;
